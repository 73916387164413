<template>
  <div>
    <vx-card>
      <div class="flex justify-between items-center mb-4">
        <vs-input
          class="mb-4 w-full md:mb-0 mr-4"
          v-model.lazy="searchQuery"
          placeholder="Buscar..."
        />
        <div>
          <vs-button
            :to="{ name: 'configuracion-crear-alarma' }"
            class="mb-4 md:mb-0"
            >Añadir</vs-button
          >
        </div>
      </div>

      <vs-table
        ref="table"
        :max-items="itemsPerPage"
        :total="total"
        :data="stateAlarms.data"
        v-if="stateAlarms.data && stateAlarms.paginatorInfo"
        no-data-text="No hay resultados"
        @change-page="setCurrentPage"
      >
        <template slot="thead">
          <vs-th sort-key="id">Ref</vs-th>
          <vs-th class="alarm-name-column" sort-key="">Nombre</vs-th>
          <vs-th sort-key="">Tiempo max.</vs-th>
          <vs-th sort-key="">Estado</vs-th>
          <vs-th sort-key="">Color</vs-th>
          <vs-th>Acciones</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <p>{{ tr.id }}</p>
              </vs-td>
              <vs-td>
                <p class="capitalize">{{ tr.name }}</p>
              </vs-td>
              <vs-td>
                <p>{{ tr.max_time + ' min.' }}</p>
              </vs-td>
              <vs-td>
                <p>
                  <vs-chip color="primary">
                    <span class="alarm-name font-bold truncate">
                      {{ tr.state.name }}
                    </span>
                  </vs-chip>
                </p>
              </vs-td>
              <vs-td>
                <div class="flex items-center h-full">
                  <div
                    class="state-color"
                    :style="`background: ${tr.color};`"
                  ></div>
                </div>
              </vs-td>
              <vs-td class="whitespace-no-wrap">
                <feather-icon
                  @click="
                    $gates.hasPermission('edit_alarms') && editStateAlarm(tr.id)
                  "
                  icon="EditIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                />
                <feather-icon
                  @click="
                    $gates.hasPermission('edit_alarms') && confirmDelete(tr.id)
                  "
                  icon="TrashIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                />
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>

      <div class="flex">
        <span v-show="$apollo.loading"><p>Cargando...</p></span>

        <vs-pagination
          class="mt-3"
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </div>
    </vx-card>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { GET_ALARMS } from '@/graphql/queries.js';
import { DELETE_ALARM } from '@/graphql/mutations';

export default defineComponent({
  data() {
    return {
      stateAlarms: [],
      searchQuery: '',
      maxPageNumbers: 5,
      itemsPerPage: 7,
      currentPage: 1,
    };
  },

  watch: {
    searchQuery: function (val) {
      this.searchQuery = val;
      this.currentPage = 1;
    },
  },

  apollo: {
    stateAlarms: {
      query: GET_ALARMS,
      variables() {
        return {
          searchText: this.searchQuery,
          page: this.currentPage,
          first: this.itemsPerPage,
        };
      },
    },
  },

  computed: {
    totalPages() {
      if (this.stateAlarms.paginatorInfo) {
        return Math.ceil(
          this.stateAlarms.paginatorInfo.total /
            this.stateAlarms.paginatorInfo.perPage
        );
      }
      return 0;
    },
    total() {
      return this.stateAlarms.paginatorInfo
        ? this.stateAlarms.paginatorInfo.total
        : 0;
    },
  },

  methods: {
    setCurrentPage(page) {
      this.currentPage = page;
    },
    editStateAlarm(id) {
      this.$router.push(`/configuracion/alarmas/editar-alarma/${id}`);
    },
    createStateAlarm() {
      this.$router.push(`/configuracion/alarmas/crear-alarma`);
    },
    confirmDelete(id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirmar Eliminación`,
        text: `La alarma con la referencia ${id} será eliminada.`,
        accept: () => this.deleteStateAlarm(id),
        acceptText: 'Eliminar',
      });
    },
    deleteStateAlarm(id) {
      this.$apollo
        .mutate({
          mutation: DELETE_ALARM,
          variables: {
            id: id,
          },
          update: (store, { data: { deleteAlarm } }) => {
            try {
              const data = store.readQuery({
                query: GET_ALARMS,
                variables: {
                  searchText: '',
                  page: 1,
                  first: 7,
                },
              });

              const removeIndex = data.stateAlarms.data
                .map((item) => item.id)
                .indexOf(deleteAlarm.id);
              ~removeIndex && data.stateAlarms.data.splice(removeIndex, 1);

              store.writeQuery({
                query: GET_ALARMS,
                variables: {
                  searchText: '',
                  page: 1,
                  first: 7,
                },
                data,
              });
            } catch {
              console.log();
            }
          },
        })
        .then(() => {
          this.$vs.notify({
            color: 'success',
            title: 'Alarma eliminada',
            text: 'La alarma fue elinada correctamente.',
          });
        })
        .catch(() => {
          this.$vs.notify({
            color: 'danger',
            title: 'Alarma no eliminada',
            text: 'La alarma no pudo ser elminada.',
          });
        });
    },
  },
});
</script>

<style lang="scss">
.alarm-name {
  max-width: 23rem;
  text-transform: capitalize;
}

.state-text {
  font-weight: bold;
}

.state-color {
  align-items: center;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.alarm-name-column {
  width: 35%;
  word-wrap: break-word;
}
</style>
